import { MarketIntelligenceDisclaimer } from "@/tools/market-intelligence/components/MarketIntelligenceDisclaimer";
import { MarketIntelligenceMetabasePages } from "@/tools/market-intelligence/components/MetabaseDashboardPages";
import { MarketIntelligenceMetabasePage } from "@/tools/market-intelligence/schema";
import { Tool } from "@/types/tools";
import { GlobalOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
const pages: MarketIntelligenceMetabasePage[] = [{
  label: "Regional",
  height: "81rem",
  metabaseId: "b6a152d4-233d-4021-b91c-cc968c3837e9",
  value: "regional"
}];
export const MarketIntelligenceRegionalToolComponent = () => {
  return <Row gutter={[0, 24]} className="mb-16" data-sentry-element="Row" data-sentry-component="MarketIntelligenceRegionalToolComponent" data-sentry-source-file="regional.tsx">
      <Col span={24} data-sentry-element="Col" data-sentry-source-file="regional.tsx">
        <MarketIntelligenceMetabasePages pages={pages} data-sentry-element="MarketIntelligenceMetabasePages" data-sentry-source-file="regional.tsx" />
      </Col>

      <Col span={24} data-sentry-element="Col" data-sentry-source-file="regional.tsx">
        <MarketIntelligenceDisclaimer data-sentry-element="MarketIntelligenceDisclaimer" data-sentry-source-file="regional.tsx" />
      </Col>
    </Row>;
};
export default {
  id: "regional",
  category: "marketIntelligence",
  keyPrefix: "marketIntelligence.regional",
  icon: <GlobalOutlined />,
  render: () => <MarketIntelligenceRegionalToolComponent />
} as Tool;