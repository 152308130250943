import { Collapse, Typography } from "antd";
const {
  Paragraph
} = Typography;
export const MarketIntelligenceDisclaimer = () => {
  return <Collapse items={[{
    key: "disclaimer",
    label: "Important Information & Disclaimer",
    children: <>
              <Paragraph>
                The information, data and inferences contained in this Ranking does not comprise or constitute and
                should not be relied upon as investment or financial advice.
              </Paragraph>

              <Paragraph>
                XDI and its affiliates (i) disclaims any and all responsibility for any errors or inaccuracies in the
                information, data and inferences contained in this Ranking; (ii) is not responsible for any use made of
                the information, data and inferences contained in this Ranking by any party; and (iii) accepts no
                liability for any action as a result of any person&apos;s or group&apos;s interpretations, deductions,
                conclusions or actions in relying on the information, data and inferences contained in this Ranking.
              </Paragraph>

              <Paragraph>
                Use of and/or reliance on the information, data and inferences contained in this Ranking is at your sole
                risk.
              </Paragraph>

              <Paragraph>
                To the full extent permitted by law, you waive and release XDI, its directors, officers, employees,
                associates, affiliates, and agents from all liability for loss, costs, liabilities, expenses or damage
                arising from the use of, or reliance on, information, data and inferences contained in this Ranking.
              </Paragraph>

              <Paragraph>
                In no event will XDI, its directors, officers, employees, associates, affiliates and agents be liable
                for any consequential loss resulting from use of or reliance on the information, data and inferences
                contained in this Ranking, being any loss, damage, claim, expense, damage and or penalty that does not
                ordinarily or naturally flow from the breach, act or omission and includes, without limitation, indirect
                loss, loss of revenue, loss of reputation, loss of profits, loss of actual or anticipated savings and
                loss of bargain.
              </Paragraph>

              <Paragraph>
                You acknowledge and agree to the matters set out in this Disclaimer and Terms by accessing the MCI data.
              </Paragraph>
            </>
  }]} data-sentry-element="Collapse" data-sentry-component="MarketIntelligenceDisclaimer" data-sentry-source-file="MarketIntelligenceDisclaimer.tsx" />;
};