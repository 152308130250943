import { generateGridPoints } from "@/tools/coordinate-generation/shape-grid-mapper/utils/grid_generator";
import { Tool } from "@/types/tools";
import { CompressOutlined } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";
const {
  Title
} = Typography;
type Marker = GeoJSON.Feature<GeoJSON.Geometry>;
const DrawTools = dynamic(() => import("./components/DrawTools"), {
  ssr: false
});
const Sidebar = dynamic(() => import("./components/Sidebar"), {
  ssr: false
});
const SiteAnalysisPicker = dynamic(() => import("./components/SiteAnalysisPicker"), {
  ssr: false
});

// Used to recentre the map to new coordinates
// const MapRecentre = ({ lat, lng, zoomLevel }: { lat: number; lng: number; zoomLevel: number }) => {
//   // const map = useMap();

//   // useEffect(() => {
//   //   // Fly to that coordinates and set new zoom level
//   //   map.flyTo([lat, lng], zoomLevel);
//   // }, [lat, lng]);
//   return null;
// };

const ShapeGridMapperTool = () => {
  const [extractedItems, setExtractedItems] = useState<null | GeoJSON.FeatureCollection>(null);
  const [gridItems, setGridItems] = useState<null | any>(null);
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [, setCentreCoords] = useState(null);
  const [drawnItems, setDrawnItems] = useState([]);
  const drawToolsRef = useRef(null);
  const handleDeleteItem = (index: number) => {
    setDrawnItems(drawnItems.filter((_, i) => i !== index));
    console.log("DrawTools is rendered:", drawToolsRef.current);
    if (drawToolsRef.current !== null) {
      // drawToolsRef.current.deleteLayerByIndex(index);
    }
  };
  const handleItemClick = (coordinates: any) => {
    setCentreCoords(coordinates);
  };

  // Trigger map item extraction
  const extractDrawnItems = () => {
    const event = new CustomEvent("extractMap");
    document.dispatchEvent(event);
  };
  useEffect(() => {
    if (extractedItems) {
      generateGridFromExtractedItems();
    }
  }, [extractedItems]);

  // Setting generated grid items
  const handleGenerateGrid = (gridPoints: any) => {
    setGridItems(gridPoints);
    console.log("Generated Grid Points:", gridPoints);
  };
  const generateGridFromExtractedItems = async () => {
    if (!extractedItems || extractedItems.features.length === 0) {
      alert("No areas marked for LSA!");
      return;
    }
    try {
      const distance = 50;
      const gridPoints = await generateGridPoints(extractedItems as any, distance);
      handleGenerateGrid(gridPoints);
    } catch (error) {
      console.error("Error generating grid:", error);
      alert("Failed to generate grid.");
    }
  };
  const convertToGeoJSON = (items: any) => {
    return items.map((layer: any) => layer.toGeoJSON());
  };
  const handleUpdateDrawnItems = (items: any) => {
    const geoJSON = convertToGeoJSON(items);
    setDrawnItems(geoJSON);
  };
  const handleUpdateGridItems = (items: any) => {
    const geoJSONFeatures = items.map((layer: any) => layer.toGeoJSON());
    const featureCollection = {
      type: "FeatureCollection",
      features: geoJSONFeatures
    };
    setGridItems(featureCollection);
  };
  return <>
      <Row gutter={[16, 0]} className="mb-8 min-h-[400px] w-full" data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <Col flex={1} data-sentry-element="Col" data-sentry-source-file="index.tsx">
          <Title level={5} className="mt-0 pt-0" data-sentry-element="Title" data-sentry-source-file="index.tsx">
            Draw Polygons and Lines
          </Title>
          <div className="h-[70vh] w-full overflow-hidden rounded-md border">
            <SiteAnalysisPicker data-sentry-element="SiteAnalysisPicker" data-sentry-source-file="index.tsx">
              <DrawTools deleteLayerByIndex={() => {
              console.log("Implement deleteLayerByIndex");
            }} markers={markers} setMarkers={setMarkers} onExtract={setExtractedItems} gridItems={gridItems} onUpdateDrawnItems={handleUpdateDrawnItems} onUpdateGridItems={handleUpdateGridItems} ref={drawToolsRef} data-sentry-element="DrawTools" data-sentry-source-file="index.tsx" />
            </SiteAnalysisPicker>
          </div>
        </Col>
        <Col flex={0} className="w-full max-w-[300px]" data-sentry-element="Col" data-sentry-source-file="index.tsx">
          <Row gutter={[0, 12]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <Title level={5} className="mt-0 pt-0" data-sentry-element="Title" data-sentry-source-file="index.tsx">
              <Space data-sentry-element="Space" data-sentry-source-file="index.tsx">Configuration</Space>
            </Title>
            <Col span={24} data-sentry-element="Col" data-sentry-source-file="index.tsx">
              <Sidebar drawnItems={drawnItems} extractDrawnItems={extractDrawnItems} gridItems={gridItems} onItemClick={handleItemClick} onDeleteItem={handleDeleteItem} data-sentry-element="Sidebar" data-sentry-source-file="index.tsx" />
            </Col>
          </Row>
        </Col>
      </Row>
    </>;
};
export default {
  id: "shapeGridMapper",
  category: "coordinateGeneration",
  keyPrefix: "coordinateGeneration.shapeGridMapper",
  icon: <CompressOutlined />,
  render: () => <ShapeGridMapperTool />,
  maxWidth: "1400px",
  hasTutorial: false
} as Tool;