import * as turf from "@turf/turf";

async function isPointOnLand(_lat: number, _lng: number) {
  console.log(`Auto-passing point: ${_lat}, ${_lng}`);
  return true;
}

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function generateGridPoints(
  geojson: turf.FeatureCollection<turf.Polygon | turf.LineString | turf.Point>,
  distance: number
) {
  const delay_ms = 1;
  const pointFeatures = [];

  // Loop over each feature in the GeoJSON
  for (const feature of geojson.features) {
    if (feature.geometry.type === "Polygon") {
      const polygon = turf.polygon(feature.geometry.coordinates);
      const bbox = turf.bbox(polygon);
      const grid = turf.pointGrid(bbox, distance, { units: "meters" });

      for (const point of grid.features) {
        if (turf.booleanPointInPolygon(point, polygon)) {
          const [lng, lat] = point.geometry.coordinates;
          const isOnLand = await isPointOnLand(lat, lng);
          if (isOnLand) {
            pointFeatures.push(point);
          }
          await delay(delay_ms);
        }
      }
    } else if (feature.geometry.type === "LineString") {
      const line = turf.lineString(feature.geometry.coordinates);
      const distanceAlongLine = turf.length(line, { units: "meters" });
      const numPoints = Math.floor(distanceAlongLine / distance);

      for (let i = 0; i <= numPoints; i++) {
        const pointOnLine = turf.along(line, i * distance, { units: "meters" });
        const [lng, lat] = pointOnLine.geometry.coordinates;
        const isOnLand = await isPointOnLand(lat, lng);
        if (isOnLand) {
          pointFeatures.push(pointOnLine);
        }
        await delay(delay_ms);
      }
    } else if (feature.geometry.type === "Point") {
      const [lng, lat] = feature.geometry.coordinates;
      const isOnLand = await isPointOnLand(lat, lng);
      if (isOnLand) {
        pointFeatures.push(feature);
      }
      await delay(delay_ms);
    }
  }

  return turf.featureCollection(pointFeatures);
}
