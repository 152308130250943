import { Avatar } from "@/components/buttons/Avatar";
import { useGetUsersQuery } from "@/store/services/supabase";
import { LockOutlined } from "@ant-design/icons";
import { useSession } from "@supabase/auth-helpers-react";
import { Tag } from "antd";
export const PortfolioExportPendingKeys = () => {
  const session = useSession();
  const {
    data: users
  } = useGetUsersQuery();
  const filteredUsers = users?.filter(u => u.has_org_keys).sort((a, b) => {
    if (a?.is_owner && !b?.is_owner) return -1;
    if (!a?.is_owner && b?.is_owner) return 1;
    return (a?.email || "").localeCompare(b?.email || "");
  });
  return <>
      <div className="mx-auto my-0 mb-4 flex h-16 w-16 items-center justify-center rounded-xl border border-gray-200 bg-white text-gray-900 shadow-lg shadow-gray-200">
        <LockOutlined className="text-2xl" data-sentry-element="LockOutlined" data-sentry-source-file="PortfolioExportPendingKeys.tsx" />
      </div>

      <h2 className="mb-4 text-center text-base font-semibold">Pending Encryption Keys</h2>

      <div className="mx-auto mb-4 flex w-max items-center gap-2 rounded-lg border border-gray-200 p-1 pr-2 text-center text-sm">
        <Avatar name={session?.user.email || ""} size={20} data-sentry-element="Avatar" data-sentry-source-file="PortfolioExportPendingKeys.tsx" />
        {session?.user.email}
      </div>
      <div className="mb-4 text-base leading-relaxed">
        Your account requires the organization encryption keys to share portfolios securely. Until then, you can still
        create encrypted private portfolios. Please try again or ask one of the following members to log in to share
        encryption keys.
        <ul className="mt-4 max-h-52 list-inside list-none divide-y overflow-scroll rounded-md border text-left text-sm text-gray-500">
          {filteredUsers?.map(f => <li key={f.email} className="px-2 py-1 even:bg-gray-100">
              {f.email} {f.is_owner && <Tag>Owner</Tag>}
            </li>)}
        </ul>
      </div>
    </>;
};