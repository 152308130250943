import { EmbedModal } from "@/features/embeds";
import { Button, Col, Row, Typography } from "antd";
import { ReactElement, useState } from "react";
const {
  Title
} = Typography;
interface MarketIntelligenceCustomDataSectionProps {
  children: ReactElement | ReactElement[];
  hubspotFormId: string;
  title: string;
}
export const MarketIntelligenceCustomDataSection = ({
  children,
  hubspotFormId,
  title
}: MarketIntelligenceCustomDataSectionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return <Row data-sentry-element="Row" data-sentry-component="MarketIntelligenceCustomDataSection" data-sentry-source-file="MarketIntelligenceCustomDataSection.tsx">
      <Title level={3} data-sentry-element="Title" data-sentry-source-file="MarketIntelligenceCustomDataSection.tsx">{title}</Title>

      {children}

      <Col span={24} data-sentry-element="Col" data-sentry-source-file="MarketIntelligenceCustomDataSection.tsx">
        <Button onClick={() => setIsModalOpen(true)} style={{
        display: "block",
        margin: "0 auto"
      }} type="primary" data-sentry-element="Button" data-sentry-source-file="MarketIntelligenceCustomDataSection.tsx">
          Request Custom Data
        </Button>
      </Col>

      <EmbedModal data={{
      type: "hubspotForm",
      id: "market-intelligence-request-form",
      formId: hubspotFormId
    }} open={isModalOpen} onCancel={() => setIsModalOpen(false)} title="Request Form" embedHeight="auto" data-sentry-element="EmbedModal" data-sentry-source-file="MarketIntelligenceCustomDataSection.tsx" />
    </Row>;
};