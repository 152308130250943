import { useGetToolUsageQuery } from "@/store/services/supabase";
import PortfolioExportFairUseWarning from "@/tools/aggregate/portfolio-export/components/PortfolioExportFairUseWarning";
import { PortfolioExportJobInformation } from "@/tools/aggregate/portfolio-export/components/PortfolioExportJobInformation";
import { PortfolioExportPendingKeys } from "@/tools/aggregate/portfolio-export/components/PortfolioExportPendingKeys";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { LoadingOutlined, SearchOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Button, Input, List, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
interface PortfolioExportJobTableBodyProps {
  schemas: PortfolioExportJobSchema[];
  loading: boolean;
  hasOrgKeys: boolean;
  handleUserRefresh: () => Promise<void>;
  updateSchema: (schema: PortfolioExportJobSchema) => void;
  onDelete: (id: string) => void;
  onError: (err: string | string[], portfolioId: string) => void;
  onEdit: (props: {
    schema: PortfolioExportJobSchema;
    name: string;
    isPrivate: boolean;
    groupId: string;
  }) => Promise<void>;
}
export const PortfolioExportJobTableBody = ({
  schemas,
  loading,
  hasOrgKeys,
  handleUserRefresh,
  updateSchema,
  onDelete,
  onError,
  onEdit
}: PortfolioExportJobTableBodyProps) => {
  const [modal, modalCtx] = Modal.useModal();
  const {
    data
  } = useGetToolUsageQuery({});
  const {
    t
  } = useTranslation("tools", {
    keyPrefix: "aggregate.portfolioExport"
  });
  const [search, setSearch] = useState("");
  const filteredSchema = search ? schemas.filter(({
    name
  }) => name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) : schemas;
  const isProcessing = schemas.filter(({
    status
  }) => ["processing", "pending", "provisioning", "running", "uploading"].includes(status)).length > 0;
  const handlePendingEncryptionClick = () => {
    modal.confirm({
      title: false,
      icon: <></>,
      width: 540,
      content: <PortfolioExportPendingKeys />,
      okText: "Refresh",
      onOk: handleUserRefresh,
      closable: true
    });
  };
  return <>
      {modalCtx}

      {data && <PortfolioExportFairUseWarning data={data} />}

      <div className="mb-4 mt-4 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Input prefix={<SearchOutlined style={{
          height: 16
        }} />} placeholder="Search..." value={search} onChange={e => setSearch(e.target.value)} className="max-w-[400px]" allowClear data-sentry-element="Input" data-sentry-source-file="PortfolioExportJobTableBody.tsx" />
          {search && <div className="text-gray-500">{filteredSchema.length} items</div>}
        </div>
        {!hasOrgKeys && <span className="flex items-center text-xs">
            <Button icon={<WarningOutlined />} size="small" type="text" onClick={handlePendingEncryptionClick}>
              Pending Encryption Keys
            </Button>
          </span>}
      </div>

      {isProcessing && <Alert message={<div className="text-sm font-medium text-sky-700">{t("processingAlert.title")}</div>} description={<div className="text-xs text-sky-700">{t("processingAlert.blurb")}</div>} rootClassName="border-none p-4 mb-4" />}

      <div className="relative overflow-x-scroll">
        <List dataSource={filteredSchema} itemLayout="vertical" pagination={false} rowKey="id" className={`mb-4 overflow-hidden rounded-lg border border-gray-200`} size="large" loading={{
        indicator: <LoadingOutlined />,
        spinning: loading,
        size: "large"
      }} renderItem={schema => {
        return <List.Item
        // TODO: Move JobActions into this property.
        // actions={[<div key="1">Wow</div>]}
        className="b-0 !border-gray-200 px-4 odd:bg-gray-50">
                <PortfolioExportJobInformation onDelete={() => onDelete(schema.id)} onEdit={onEdit} schema={schema} updateSchema={updateSchema} onError={onError} />
              </List.Item>;
      }} data-sentry-element="List" data-sentry-source-file="PortfolioExportJobTableBody.tsx" />
      </div>
    </>;
};