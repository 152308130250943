import { encryptSecret, importKey } from "@/features/cryptography";
import supabase from "@/features/supabase";
import { useGetPendingUsersForOrgKeyExchangeQuery, useSetOrgSecretsForPendingUsersMutation } from "@/store/services/supabase";
import { arrayBufferToString } from "@/utils/crypto";
import { useKeys } from "@/utils/hooks/useKeys";
import { CloseOutlined } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import { PropsWithChildren, useEffect } from "react";
export const OrgKeyExchange = ({
  children
}: PropsWithChildren) => {
  const [keys] = useKeys();
  const {
    data: members,
    refetch
  } = useGetPendingUsersForOrgKeyExchangeQuery();
  const [setOrgKeys] = useSetOrgSecretsForPendingUsersMutation();
  const [messageApi, messageContext] = message.useMessage();
  const [modal, modalContext] = Modal.useModal();
  const handleModalOpen = (members: any[]) => {
    modal.info({
      width: 500,
      content: <div>
          Encryption keys successfully exchanged with the following member{members.length > 1 ? "s" : ""} of your
          organisation. No further action required.
          <ul className="list-inside list-disc">
            {members.map(m => <li key={m.user_id}>{m.email}</li>)}
          </ul>
        </div>
    });
  };
  const handleOrgKeyExchange = async () => {
    console.log("handleOrgKeyExchange");
    const secret = keys.orgSecret;
    if (!secret) return;
    if (!members || !members.length) return;
    if (members) {
      const withEncryptedSecrets = await Promise.all(members.map(async user => {
        let org_secret_enc = "";
        try {
          const encryptedSecret = await encryptSecret({
            publicKey: await importKey(user.public_key, ["encrypt"]),
            secret
          });
          org_secret_enc = arrayBufferToString(encryptedSecret);
        } catch {
          console.error("Error adding Org Secret to: ", user.email);
        }
        return {
          user_id: user.id,
          group_id: user.group_id,
          email: user.email,
          org_secret_enc
        };
      })).then(members => members.filter(member => member.org_secret_enc !== ""));
      const res = await setOrgKeys(withEncryptedSecrets);
      if ("error" in res) {
        console.error(res);
        return;
      }
      if (!withEncryptedSecrets.length) {
        return;
      }
      const MESSAGE_ID = `MSG-${new Date().getTime()}`;
      messageApi.success({
        key: MESSAGE_ID,
        duration: 30,
        content: <div className="flex items-center">
            <span className="pr-6">
              Encryption keys exchanged with {withEncryptedSecrets.length} member
              {withEncryptedSecrets.length > 1 ? "s" : ""}.
            </span>
            <Button type="text" size="small" onClick={() => {
            handleModalOpen(withEncryptedSecrets);
            messageApi.destroy(MESSAGE_ID);
          }}>
              Details
            </Button>
            <Button type="text" size="small" icon={<CloseOutlined />} onClick={() => messageApi.destroy(MESSAGE_ID)} />
          </div>
      });
    }
  };
  useEffect(() => {
    handleOrgKeyExchange();
  }, [members, keys]);
  supabase.channel("user_update").on("postgres_changes", {
    event: "UPDATE",
    schema: "public",
    table: "users"
  }, refetch).subscribe();
  return <>
      {messageContext}
      {modalContext}

      {children}
    </>;
};